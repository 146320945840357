<template>
    <ContentWrapper>
        <breadcrumb :name="$t('common.layout.sidebar.nav.plants')"/>

        <div class="container">

            <b-row>
                <b-col sm="6">
                    <div class="card card-default card-stat animated-fast fadeInLeft">
                        <div class="card-header">
                            {{ $t('growing.view.plants.heading') }}<i class="card-header-icon fad fa-seedling"></i>
                        </div>
                        <div class="card-body">

                            <i v-if="stats.has_upgradeable_plants" class="fad fa-exclamation-triangle text-danger mr-2"></i>

                            <span class="mr-2" v-if="stats.plants.level_1 > 0">
                                <img src="../asstes/svg/plant-levels/plant-level-1.svg" width="60px" style="margin-top: -15px; margin-bottom: -15px;" class="mw-100 mr-1">
                                {{ stats.plants.level_1 }}
                            </span>
                            <span class="mr-2" v-if="stats.plants.level_2 > 0">
                                <img src="../asstes/svg/plant-levels/plant-level-2.svg" width="60px" style="margin-top: -15px; margin-bottom: -15px;" class="mw-100 mr-1">
                                {{ stats.plants.level_2 }}
                            </span>
                            <span class="mr-2" v-if="stats.plants.level_3 > 0">
                                <img src="../asstes/svg/plant-levels/plant-level-3.svg" width="60px" style="margin-top: -15px; margin-bottom: -15px;" class="mw-100 mr-1">
                                {{ stats.plants.level_3 }}
                            </span>

                        </div>
                    </div>
                </b-col>
                <b-col sm="6">
                    <router-link :to="{name: 'Growing:SettingsPlants'}" tag="div" class="card card-default card-stat animated-fast fadeInLeft" style="cursor: pointer">
                        <div class="card-header">
                            Settings<i class="card-header-icon fad fa-seedling"></i>
                        </div>
                        <div class="card-body">
                            <span :class="{'text-primary': !!stats.transfer_enabled_at}">
                                <i class="fad fa-wrench mr-2"></i> <small>{{ $t('growing.view.plants.button_transfer') }}</small>
                            </span>
                        </div>
                    </router-link>
                </b-col>
            </b-row>

            <router-view/>

        </div>
    </ContentWrapper>
</template>
<style>

    .pointer {
        cursor: pointer;
    }

    .card-image .card-body div {
        overflow: hidden;
    }

    .badge-yellow {
        color: #292929;
        background-color: #ffdf32;
    }
</style>
<script>

    export default {
        data() {
            return {
                stats: {
                    plants_active: 0,
                    plants: {
                        level_1: 0,
                        level_2: 0,
                        level_3: 0,
                    },
                    enable_service_cost_exchange: false,
                    transfer_enabled_at: null
                },
            }
        },
        mounted() {
            this.$api.get('growing/plants/overview').then(response => {
                this.stats = response.data;

                if (this.stats.plants_active === 0) {
                    this.$swal.fire({
                        title: 'No plants found!',
                        text: 'You need at least one plant to get access to this page!',
                        type: 'warning'
                    });
                    this.$router.push({name: 'Growing:Sale'})
                }
            });
        }
    }
</script>
